<template>
  <div>
    <div class="notfound-title">
      {{ $t("notFoundTitle") }}
    </div>

    <div class="notfound-content">
      {{ $t("notFoundContent") }}
    </div>

    <el-button class="confirm" @click="$router.replace({ path: '/' })">
      {{ $t("notFoundBtn") }}
    </el-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.notfound-title {
  @include nav-title(26px, 39px, 26px);
}

.notfound-content {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
}
</style>
